// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
// import { mobileCheck } from "./functions/mobile-check.js";
// console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
import { burger } from './functions/burger.js';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение свайпера
// import Swiper from 'swiper';
// import { Navigation, Pagination } from 'swiper/modules';
// Swiper.use([Navigation, Pagination]);
// const swiper = new Swiper(el, {
//   slidesPerView: 'auto',
// });

// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
// import SmoothScroll from 'smooth-scroll';
// const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

// import { validateForms } from './functions/validate-forms';
// const rules1 = [...];

// const afterForm = () => {
//   console.log('Произошла отправка, тут можно писать любые действия');
// };

// validateForms('.form-1', rules1, afterForm);

export const addTokenToForms = function (form) {

  if (form === 'all') {

    const forms = document.querySelectorAll("form");

    forms.forEach(form => {
        let captchaField = form.querySelector('input[name="g_recaptcha_response"]');

        if (!captchaField) {
            captchaField = document.createElement("input");
            captchaField.type = "hidden";
            captchaField.name = "g_recaptcha_response";
            form.appendChild(captchaField);
        }

        grecaptcha.ready(() => {
            grecaptcha.execute('6LcM9VcqAAAAAJbxz8O0LBFkeGLcL6kgC7NRA84T', { action: 'submit' }).then(token => {
                captchaField.value = token;
            });
        });
    });

  } else {
    let captchaField = form.querySelector('input[name="g_recaptcha_response"]');

    if (!captchaField) {
      captchaField = document.createElement("input");
      captchaField.type = "hidden";
      captchaField.name = "g_recaptcha_response";
      form.appendChild(captchaField);
    }

    grecaptcha.ready(() => {
      grecaptcha.execute('6LcM9VcqAAAAAJbxz8O0LBFkeGLcL6kgC7NRA84T', { action: 'submit' }).then(token => {
        captchaField.value = token;
      });
    });

  }

};

addTokenToForms('all');
