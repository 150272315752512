import JustValidate from 'just-validate';
import Inputmask from "../../../node_modules/inputmask/dist/inputmask.es6.js";
import { disableScroll } from '../functions/disable-scroll.js';
import { addTokenToForms } from '../_functions.js';

export const validateForms = (selector, rules, checkboxes = [], afterSend) => {
  const form = document?.querySelector(selector);

  form._submit_ = function () {

    form._validation_.revalidate().then(async isValid => {

        if (!isValid) return grecaptcha.reset();

        let formData = new FormData(form);
        let xhr = new XMLHttpRequest();

        let formSucSendPopup = document.querySelector('.formsend_succesful');
        let formErSendPopup = document.querySelector('.formsend_error');

        let formSubmitButton = form.querySelector('.form__submit-button');
        formSubmitButton.setAttribute('disabled', '');

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {

            try {
              const response = JSON.parse(xhr.responseText);

              if (response.status === 'success') {

                form.parentElement.parentElement.classList.remove('active');
                formSucSendPopup.classList.add('active');

                form.reset();
                grecaptcha.reset();

                handleMetrics();

                addTokenToForms(form);

              } else {

                form.parentElement.parentElement.classList.remove('active');
                formErSendPopup.classList.add('active');

              }
            } catch (e) {

            }
          }
        };

        xhr.open('POST', 'mail.php', true);
        xhr.send(formData);
    });
  };

  function handleMetrics() {
      ym(98541057, 'reachGoal', 'obschaya');

      if (form.classList.contains('credit-form')) {
          ym(98541057, 'reachGoal', 'reshenie');
      }

      if (form.classList.contains('popup-form')) {
          ym(98541057, 'reachGoal', 'zayavka');
      }
  }


  const telSelector = form?.querySelector('input[type="tel"]');

  if (!form) {
    console.error('Нет такого селектора!');
    return false;
  }

  if (!rules) {
    console.error('Вы не передали правила валидации!');
    return false;
  }

  if (telSelector) {
    const inputMask = new Inputmask('+7 (999) 999-99-99');
    inputMask.mask(telSelector);

    for (let item of rules) {
      if (item.tel) {
        item.rules.push({
          rule: 'function',
          validator: function() {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: item.telError
        });
      }
    }
  }



  const validation = new JustValidate(selector);
  form._validation_ = validation;

  for (let item of rules) {
    validation
      .addField(item.ruleSelector, item.rules);
  }

  if (checkboxes.length) {
    for (let item of checkboxes) {
      validation
        .addRequiredGroup(
          `${item.selector}`,
          `${item.errorMessage}`
        )
    }
  }

};
